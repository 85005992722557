<template>
  <div class="d-flex justify-content-center flex-wrap">
    <!-- Result area -->
    <div class="d-flex home-scanning">
      <Result />
    </div>

    <div class="d-flex home-camera">
      <Camera />
    </div>

    <!-- Selected area -->
    <div class="d-flex home-selected">
      <Selected />
    </div>
  </div>
</template>

<script>
import Result from "@/components/home/result.vue";
import Camera from "@/components/home/camera.vue";
import Selected from "@/components/home/selected.vue";

export default {
  components: {
    Result,
    Camera,
    Selected,
  },
};
</script>
