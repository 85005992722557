export const getters = {
  modalDetail: (state) => {
    return state.modalDetail;
  },
  modalCategories: (state) => {
    return state.modalCategories;
  },
  modalConfirm: (state) => {
    return state.modalConfirm;
  },
  modalReport: (state) => {
    return state.modalReport;
  },
  modalInputSaveName: (state) => {
    return state.modalInputSaveName;
  },
};
