import { ROUTE_NAME, CookieKey } from "@/utils/constants";

export const AppMixin = {
  data() {
    return {
      ROUTE_NAMES: Object.freeze(ROUTE_NAME),
      COOKIES: Object.freeze(CookieKey),
    };
  },
};
