export const state = {
  modalDetail: {
    show: false,
    item: null,
    selections: [],
  },
  modalCategories: {
    show: false,
  },
  modalConfirm: {
    show: false,
    title: "",
    message: "",
    callback: null,
  },
  modalReport: {
    show: false,
    callback: null,
  },
  modalInputSaveName: {
    show: false,
    callback: null,
  },
};
