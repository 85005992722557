<template>
  <!-- Home header -->
  <div v-if="headerType === 1" class="navbar-action d-flex align-items-center">
    <div class="d-flex w-100">
      <!-- Left side -->
      <div class="d-flex align-items-center justify-content-start w-15">
        <a
          v-if="currentRoute === ROUTE_NAMES.HOME"
          href="#"
          style="color: gray"
        >
          <i class="fa fa-bars fa-lg" />
        </a>
        <router-link
          v-else
          :to="{ name: ROUTE_NAMES.SAVED_LIST }"
          style="color: gray"
          @click="onBackToSaveList()"
        >
          <i class="fa fa-arrow-left fa-lg"></i>
        </router-link>
      </div>

      <!-- Candidate list -->
      <div
        class="d-flex align-items-center justify-content-center"
        :class="[notMatchImage === '' ? 'w-65' : 'w-30']"
      >
        <h3 v-if="currentRoute === ROUTE_NAMES.HOME">
          {{ $t("candidate_list") }}
        </h3>
        <h2 v-else>{{ $route.params.title }}</h2>
      </div>

      <!-- Right side -->
      <div
        class="d-flex align-items-center justify-content-end pe-2"
        :class="[notMatchImage === '' ? 'w-20' : 'w-55']"
      >
        <button
          v-if="notMatchImage !== ''"
          type="button"
          class="btn btn-danger btn-sm me-1 h-100"
          @click="onClickReport()"
        >
          {{ $t("modal.report_title") }}
        </button>
        <button
          v-if="selectionList.length > 0 && currentRoute === ROUTE_NAMES.HOME"
          type="button"
          class="btn btn-action btn-sm h-100"
          @click="onSaveSelection()"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>

  <!-- List header -->
  <div v-else class="navbar-action d-flex align-items-center w-100">
    <!-- Left side -->
    <div class="d-flex justify-content-start w-25">
      <!-- Button back page -->
      <router-link
        v-if="!deleteMode"
        :to="{ name: ROUTE_NAMES.HOME }"
        style="color: gray"
      >
        <i class="fa fa-arrow-left fa-lg"></i>
      </router-link>

      <!-- Release mode -->
      <a
        v-else
        href="#"
        class="text-decoration-none"
        @click="OnClickDeleteModeBtn()"
      >
        <span class="text-blue">{{ $t("mode.release") }}</span>
      </a>
    </div>

    <!-- Title side -->
    <div class="d-flex justify-content-center w-50">
      <h2 v-if="currentRoute === ROUTE_NAMES.SELECTED_LIST">
        {{ $t("selection_list") }}
      </h2>
      <h2 v-else>{{ $t("save_list") }}</h2>
    </div>

    <!-- Right side -->
    <div class="d-flex justify-content-end w-25 pe-1">
      <!-- Delete mode -->
      <a
        v-if="!deleteMode"
        href="#"
        class="text-decoration-none"
        @click="OnClickDeleteModeBtn()"
      >
        <span class="text-red">{{ $t("mode.delete") }}</span>
      </a>

      <!-- Delete button -->
      <button
        v-else
        type="button"
        class="btn btn-danger btn-sm"
        @click="onDelete"
      >
        {{ $t("delete") }}
      </button>
    </div>
  </div>
</template>

<script>
import { AppMixin } from "@/mixins/AppMixin";
import { ModalMixin } from "@/mixins/ModalMixin";
import { mapActions, mapGetters } from "vuex";
import {
  EventBus,
  EVENT_CLICK_DELETE_SELECTION,
  EVENT_CLICK_DELETE_SAVED,
} from "@/utils/event-bus";
import RevampService from "@/services/revamp";

export default {
  mixins: [AppMixin, ModalMixin],
  computed: {
    ...mapGetters("app", ["deleteMode", "notMatchImage", "selectionList"]),
    headerType() {
      return this.$route.meta?.header;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions({
      _changeDetailMode: "app/changeDetailMode",
      _setNotMatchImage: "app/setNotMatchImage",
      _setPredictResult: "app/setPredictResult",
      _removeAllSelection: "app/removeAllSelection",
      _setSaveList: "app/setSaveList",
    }),
    OnClickDeleteModeBtn() {
      this._changeDetailMode();
    },
    onClickReport() {
      this.showReport({
        callback: async (text) => {
          const res = await RevampService.postReportImage(
            this.notMatchImage,
            text
          );
          if (res.status === 1) {
            // this._setNotMatchImage("");
            this.$toast.success(this.$t("toast.success"));
          } else {
            this.$toast.error("Error");
          }
        },
      });
    },
    onDelete() {
      if (this.currentRoute === this.ROUTE_NAMES.SELECTED_LIST) {
        EventBus.$emit(EVENT_CLICK_DELETE_SELECTION, true);
      } else if (this.currentRoute === this.ROUTE_NAMES.SAVED_LIST) {
        EventBus.$emit(EVENT_CLICK_DELETE_SAVED, true);
      }
    },
    onSaveSelection() {
      this.showInputSaveName({
        callback: (text) => {
          this._setSaveList({
            title: text,
            items: this.selectionList,
          });
          this._removeAllSelection();
        },
      });
    },
    onBackToSaveList() {
      this._removeAllSelection();
    },
  },
};
</script>
