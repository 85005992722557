<template>
  <div id="container-scroller">
    <AppNavbar />
    <div class="container-fluid page-body-wrapper">
      <div class="main-panel position-relative main-panel-fullscreen">
        <AppModals />
        <router-view />
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from "@/components/app/navbar.vue";
import AppFooter from "@/components/app/footer.vue";
import AppModals from "@/components/modals";
import { mapActions } from "vuex";
import { AppMixin } from "@/mixins/AppMixin";

export default {
  mixins: [AppMixin],
  components: {
    AppNavbar,
    AppFooter,
    AppModals,
  },
  mounted() {
    const selectionList = localStorage.getItem(this.COOKIES.SELECTION);
    const category = localStorage.getItem(this.COOKIES.CATEGORY);
    const saveList = localStorage.getItem(this.COOKIES.SAVE);
    if (selectionList) {
      this._setSelectionList(JSON.parse(selectionList));
    }
    if (category) {
      this._setCategory(category);
    }
    if (saveList) {
      this._setAllSaveList(JSON.parse(saveList));
    }
  },
  methods: {
    ...mapActions({
      _setSelectionList: "app/setSelectionList",
      _setCategory: "app/setCategory",
      _setAllSaveList: "app/setAllSaveList",
    }),
  },
};
</script>

<style lang="scss">
@import "css/app.css";
</style>
