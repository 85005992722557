<template>
  <div v-if="modalInputSaveName.show" class="modal-backdrop">
    <div
      class="modal fade"
      :class="{ show: modalInputSaveName.show }"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="height: 200px">
          <!-- Header -->
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("modal.save_title") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- Body -->
          <div class="modal-body" style="height: 80px">
            <div
              class="d-flex flex-column align-items-center justify-content-center w-100 h-100"
            >
              <!-- Note -->
              <div
                class="d-flex align-items-center justify-content-center w-100 pb-2"
              >
                <span class="text-12">{{ $t("modal.save_note") }}</span>
              </div>

              <!-- Input -->
              <div class="d-flex w-100 pt-2">
                <input
                  type="text"
                  class="form-control"
                  v-model="textName"
                  :placeholder="$t('modal.save_placeholder')"
                />
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="modal-footer" style="height: 70px">
            <div class="d-flex align-items-center justify-content-end w-100">
              <div class="d-flex justify-content-end w-100">
                <div class="d-inline pe-2">
                  <button type="button" class="btn btn-danger" @click="close()">
                    {{ $t("modal.cancel") }}
                  </button>
                </div>
                <div class="d-inline">
                  <button type="button" class="btn btn-primary" @click="onOk()">
                    {{ $t("save") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ModalMixin } from "@/mixins/ModalMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [ModalMixin],
  data() {
    return {
      textName: "",
    };
  },
  computed: {
    ...mapGetters("modals", ["modalInputSaveName"]),
  },
  methods: {
    close() {
      this.hideInputSaveName();
      this.textName = "";
    },
    onOk() {
      if (this.modalInputSaveName.callback) {
        this.modalInputSaveName.callback(this.textName);
      }
      this.close();
    },
  },
};
</script>
