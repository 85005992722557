<template>
  <div v-if="modalDetail.show" class="modal-backdrop">
    <div
      class="modal fade"
      :class="{ show: modalDetail.show }"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <!-- Header -->
          <div class="modal-header">
            <div class="d-flex">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("modal.detail_title") }}
              </h5>
              <button
                type="button"
                class="btn btn-danger btn-sm ms-1"
                style="color: #fff"
                @click="onDeleteItem()"
              >
                <i class="fa fa-trash-alt" />
              </button>
            </div>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- Body -->
          <div class="modal-body" style="background: #f7f7f7">
            <swiper
              ref="selectionSwiper"
              class="navigator-swiper"
              :options="navigatorSwiperOptions"
            >
              <swiper-slide
                v-for="(item, index) in modalDetail.selections"
                :key="index"
              >
                <!-- Swipper -->
                <swiper
                  class="pagination-swiper"
                  :options="paginationSwiperOptions"
                >
                  <swiper-slide
                    v-for="(img, index) in item.images"
                    :key="index"
                  >
                    <img :src="img" class="img-fluid h-100" />
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>

                <!-- Name -->
                <div class="detail px-3 overflow-auto">
                  <div
                    class="d-flex align-items-center justify-content-center w-100 py-3"
                  >
                    <h3 class="text-center">{{ item.sku_name }}</h3>
                  </div>

                  <!-- Date time -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-30">
                      {{ $t("created_date") }}
                    </div>
                    <div class="d-flex justify-content-end w-70">
                      {{ item.date }} {{ item.time }}
                    </div>
                  </div>

                  <!-- sku_div_name -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-50">
                      {{ $t("classification") }}
                    </div>
                    <div class="d-flex justify-content-end w-50">
                      <span class="text-truncate">
                        {{ item.sku_div_name }}
                      </span>
                    </div>
                  </div>

                  <!-- Price -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-50">
                      {{ $t("price") }}
                    </div>
                    <div class="d-flex justify-content-end w-50">
                      {{ item.price }} 円
                    </div>
                  </div>

                  <!-- sku_bunrui1_name -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-30">
                      {{ $t("classification_1") }}
                    </div>
                    <div class="d-flex justify-content-end w-70">
                      <span class="text-truncate">
                        {{ item.sku_bunrui1_name }}
                      </span>
                    </div>
                  </div>

                  <!-- sku_bunrui2_name -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-30">
                      {{ $t("classification_2") }}
                    </div>
                    <div class="d-flex justify-content-end w-70">
                      <span class="text-truncate">
                        {{ item.sku_bunrui2_name }}
                      </span>
                    </div>
                  </div>

                  <!-- sku_bunrui3_name -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-30">
                      {{ $t("classification_3") }}
                    </div>
                    <div class="d-flex justify-content-end w-70">
                      <span class="text-truncate">
                        {{ item.sku_bunrui3_name }}
                      </span>
                    </div>
                  </div>

                  <!-- sku_bunrui4_name -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-30">
                      {{ $t("classification_4") }}
                    </div>
                    <div class="d-flex justify-content-end w-70">
                      <span class="text-truncate">
                        {{ item.sku_bunrui4_name }}
                      </span>
                    </div>
                  </div>

                  <!-- item_conditon_name -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-50">
                      {{ $t("conditon_name") }}
                    </div>
                    <div class="d-flex justify-content-end w-50">
                      {{ item.item_conditon_name }}
                    </div>
                  </div>

                  <!-- sku_cd -->
                  <div
                    class="d-flex w-100 pt-3 pb-2 px-2 m-0"
                    style="border-top: 1px dashed gray"
                  >
                    <div class="d-flex justify-content-start w-50">
                      {{ $t("sku_cd") }}
                    </div>
                    <div class="d-flex justify-content-end w-50">
                      {{ item.sku_cd }}
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AppMixin } from "@/mixins/AppMixin";
import { ModalMixin } from "@/mixins/ModalMixin";
import { mapActions, mapGetters } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  mixins: [ModalMixin, AppMixin],
  computed: {
    ...mapGetters("modals", ["modalDetail"]),
  },
  watch: {
    "modalDetail.show": function (val) {
      if (val) {
        this.$nextTick(() => {
          const idx = this.modalDetail.selections.findIndex((item) => {
            return item.sku_cd === this.modalDetail.item.sku_cd;
          });
          if (idx !== -1) {
            this.$refs.selectionSwiper.$swiper.slideTo(idx, 200, false);
          } else {
            this.$refs.selectionSwiper.$swiper.slideTo(0, 200, false);
          }
        });
      }
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      paginationSwiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
      },
      navigatorSwiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    ...mapActions({
      _removeItemFromSaveList: "app/removeItemFromSaveList",
      _removeToSelectionList: "app/removeToSelectionList",
    }),
    close() {
      this.hideDetail();
    },
    onDeleteItem() {
      this.showConfirm({
        title: this.$t("delete"),
        message: this.$t("modal.delete_it_question"),
        callback: (ok) => {
          if (ok) {
            const currentIndex = this.$refs.selectionSwiper.$swiper.activeIndex;
            if (this.$route.name === this.ROUTE_NAMES.NEW_SAVE) {
              this._removeItemFromSaveList({
                title: this.$route.params.title,
                skuCd: this.modalDetail.selections[currentIndex].sku_cd,
              });
            } else {
              this._removeToSelectionList(
                this.modalDetail.selections[currentIndex].sku_cd
              );
            }
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  height: 80%;
}

.navigator-swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    background: #fff;
    border-radius: 10px;
  }

  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-next {
    right: 0px;
  }
}

.pagination-swiper {
  height: 20%;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
}
</style>
