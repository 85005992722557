<template>
  <div class="d-flex justify-content-center flex-wrap h-100">
    <div class="selected-group">
      <div
        v-if="selectionList.length > 0"
        class="selected-group-scrollable h-100 w-100 m-0"
      >
        <div v-if="deleteMode" class="form-check pb-2">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckChecked"
            @change="onSelectAll($event)"
          />
          <label class="form-check-label" for="flexCheckChecked">
            {{ $t("mode.all") }}
          </label>
        </div>
        <div
          v-for="(item, index) in selectionList"
          :key="index"
          class="card card-height mx-0 mt-1 mb-2"
        >
          <div class="card-body h-100">
            <!-- Check box -->
            <div
              v-if="deleteMode"
              class="selected-item w-5 align-items-center justify-content-center me-1"
            >
              <input
                v-model="deleteList"
                type="checkbox"
                :value="item.sku_cd"
                class="form-check-input"
                @change="onChecked($event, item.sku_cd)"
              />
            </div>

            <!-- Image -->
            <div class="selected-item text-center w-20">
              <img :src="item.images[0]" alt="" class="img-fluid h-100" />
            </div>

            <!-- SKU Name -->
            <div
              class="selected-item text-center align-items-center"
              :class="[deleteMode ? 'w-40' : 'w-45']"
            >
              <span v-tooltip:top="item.sku_name" class="text-truncate">
                {{ item.sku_name }}
              </span>
            </div>

            <!-- Time -->
            <div class="selected-item text-center w-25 justify-content-center">
              <div class="d-flex flex-column justify-content-center h-100">
                <span class="w-100">{{ item.date }}</span>
                <span class="w-100">{{ item.time }}</span>
              </div>
            </div>

            <!--Deleted -->
            <div
              class="selected-item text-end w-10 align-items-center justify-content-end"
            >
              <a href="#" @click.stop="deleteItem(item.sku_cd)">
                <i class="fa fa-trash-alt" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex align-items-center justify-content-center h-100 w-100"
      >
        <span class="text-gray">{{ $t("selected_note") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus, EVENT_CLICK_DELETE_SELECTION } from "@/utils/event-bus";
import { ModalMixin } from "@/mixins/ModalMixin";

export default {
  mixins: [ModalMixin],
  data() {
    return {
      deleteList: [],
    };
  },
  created() {
    this.setUpEventBus();
  },
  destroyed() {
    this.clearEventBus();
  },
  computed: {
    ...mapGetters("app", ["selectionList", "deleteMode"]),
  },
  methods: {
    ...mapActions({
      _removeToSelectionList: "app/removeToSelectionList",
    }),
    onSelectAll(event) {
      if (event.target.checked) {
        this.selectionList.forEach((item) => {
          this.deleteList.push(item.sku_cd);
        });
      } else {
        this.deleteList = [];
      }
      console.log(this.deleteList);
    },
    onChecked(event, skuCd) {
      const idx = this.deleteList.indexOf(skuCd);
      if (event.target.checked && idx === -1) {
        this.deleteList.push(skuCd);
      } else if (!event.target.checked && idx !== -1) {
        this.deleteList.splice(idx, 1);
      }
    },
    deleteItem(sku_cd) {
      this.showConfirm({
        title: this.$t("delete"),
        message: this.$t("modal.delete_it_question"),
        callback: (ok) => {
          if (ok) {
            this._removeToSelectionList(sku_cd);
          }
        },
      });
    },
    onDeleteAllSelected() {
      this.showConfirm({
        title: this.$t("delete"),
        message: this.$t("modal.delete_them_question"),
        callback: (ok) => {
          if (ok) {
            this.deleteList.forEach((item) => {
              this._removeToSelectionList(item);
            });
          }
        },
      });
    },
    setUpEventBus() {
      EventBus.$on(EVENT_CLICK_DELETE_SELECTION, this.onDeleteAllSelected);
    },
    clearEventBus() {
      EventBus.$off(EVENT_CLICK_DELETE_SELECTION, this.onDeleteAllSelected);
    },
  },
};
</script>

<style scoped>
.selected-group {
  height: 100%;
  width: 100%;
  padding-top: 10px;
}

.selected-group > .selected-group-scrollable {
  flex: 1 1 0;
  overflow-y: auto;
  white-space: nowrap;
}

.selected-group > .selected-group-scrollable >>> .selected-item {
  display: inline-flex;
  height: 100%;
  vertical-align: middle;
}

.card-height {
  height: 80px;
}
</style>
