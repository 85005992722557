<template>
  <div>
    <ModalDetail />
    <ModalCategories />
    <ModalConfirm />
    <ModalReport />
    <ModalInputSave />
  </div>
</template>

<script>
import ModalDetail from "./ModalDetail.vue";
import ModalCategories from "./ModalCategories.vue";
import ModalConfirm from "./ModalConfirm.vue";
import ModalReport from "./ModalReport.vue";
import ModalInputSave from "./ModalInputSave.vue";

export default {
  components: {
    ModalDetail,
    ModalCategories,
    ModalConfirm,
    ModalReport,
    ModalInputSave,
  },
};
</script>
