<template>
  <div class="selected-group">
    <div
      v-if="saveListItems.length > 0"
      class="selected-group-scrollable h-100 w-100 m-0"
    >
      <div
        v-for="(item, index) in saveListItems"
        :key="index"
        class="card h-35 mx-0 mt-1 mb-2"
        @click="showModalDetail(item)"
      >
        <div class="card-body h-100">
          <!-- Image -->
          <div class="selected-item text-center w-20">
            <img :src="item.images[0]" alt="" class="img-fluid h-100" />
          </div>

          <!-- SKU Name -->
          <div class="selected-item text-center w-45 align-items-center">
            <span v-tooltip:top="item.sku_name" class="text-truncate">
              {{ item.sku_name }}
            </span>
          </div>

          <!-- Time -->
          <div class="selected-item text-center w-25">
            <div class="d-flex flex-column justify-content-center h-100">
              <span class="w-100">{{ item.date }}</span>
              <span class="w-100">{{ item.time }}</span>
            </div>
          </div>

          <!--Deleted -->
          <div
            class="selected-item text-end w-10 align-items-center justify-content-end"
          >
            <a href="#" @click.stop="deleteItem(item.sku_cd)">
              <i class="fa fa-trash-alt" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="d-flex align-items-center justify-content-center h-100 w-100"
    >
      <span class="text-gray">{{ $t("selected_note") }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ModalMixin } from "@/mixins/ModalMixin";

export default {
  name: "Selected",
  mixins: [ModalMixin],
  mounted() {
    const title = this.$route.params.title;
    this._setSaveListItems(title);
  },
  computed: {
    ...mapGetters("app", ["saveListItems"]),
  },
  methods: {
    ...mapActions({
      _setSaveListItems: "app/setSaveListItems",
      _removeItemFromSaveList: "app/removeItemFromSaveList",
    }),
    deleteItem(skuCd) {
      this.showConfirm({
        title: this.$t("delete"),
        message: this.$t("modal.delete_it_question"),
        callback: (ok) => {
          if (ok) {
            this._removeItemFromSaveList({
              title: this.$route.params.title,
              skuCd: skuCd,
            });
          }
        },
      });
    },
    showModalDetail(item) {
      this.showDetail({
        item: item,
        selections: this.saveListItems,
      });
    },
  },
};
</script>

<style scoped>
.selected-group {
  height: 100%;
  width: 100%;
  padding-top: 10px;
}

.selected-group > .selected-group-scrollable {
  flex: 1 1 0;
  overflow-y: auto;
  white-space: nowrap;
}

.selected-group > .selected-group-scrollable >>> .selected-item {
  display: inline-flex;
  height: 100%;
  vertical-align: top;
}
</style>
