export const Constants = {
  REVAMP_PREDICT: process.env.VUE_APP_REVAMP_PREDICT || "localhost",
  REVAMP_INFO: process.env.VUE_APP_REVAMP_INFO || "localhost",
  REVAMP_API_KEY: process.env.VUE_APP_API_KEY || "",
};

export const ROUTE_NAME = {
  HOME: "Home",
  SELECTED_LIST: "SelectedList",
  SAVED_LIST: "SavedList",
  NEW_SAVE: "NewSave",
};

export const CookieKey = {
  SELECTION: "selection",
  CATEGORY: "category",
  SAVE: "save",
};
