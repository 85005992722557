<template>
  <div v-if="modalCategories.show" class="modal-backdrop">
    <div
      class="modal fade"
      :class="{ show: modalCategories.show }"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <!-- Header -->
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("modal.category_title") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- Body -->
          <div
            v-if="categories.length === 0"
            class="modal-body d-flex align-items-center justify-content-center"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else class="modal-body overflow-auto">
            <div
              v-for="(item, index) in categories"
              :key="index"
              class="d-flex align-items-center"
            >
              <!-- Left item -->
              <div
                v-if="index % 2 === 0"
                class="d-flex w-50 align-items-center"
              >
                <div class="form-check pb-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    :id="'rdBtn_' + index"
                    name="flexCheckChecked"
                    @change="onSelectChanged($event, categories[index])"
                    :checked="category === categories[index]"
                  />
                  <label
                    class="form-check-label text-black"
                    :for="'rdBtn_' + index"
                  >
                    {{ categories[index] }}
                  </label>
                </div>
              </div>

              <!-- Right item -->
              <div
                v-if="index % 2 === 0 && categories[index + 1]"
                class="d-flex w-50 align-items-center"
              >
                <div class="form-check pb-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexCheckChecked"
                    :id="'rdBtn_' + (index + 1)"
                    @change="onSelectChanged($event, categories[index + 1])"
                    :checked="category === categories[index + 1]"
                  />
                  <label
                    class="form-check-label text-black"
                    :for="'rdBtn_' + (index + 1)"
                  >
                    {{ categories[index + 1] }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="modal-footer">
            <div class="d-flex align-items-center justify-content-end w-100">
              <div class="d-flex justify-content-end w-100">
                <div class="d-inline pe-2">
                  <button type="button" class="btn btn-danger" @click="close()">
                    {{ $t("modal.cancel") }}
                  </button>
                </div>
                <div class="d-inline">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="onClickSelect()"
                  >
                    {{ $t("modal.select") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ModalMixin } from "@/mixins/ModalMixin";
import { mapActions, mapGetters } from "vuex";
import RevampService from "@/services/revamp";

export default {
  mixins: [ModalMixin],
  computed: {
    ...mapGetters("modals", ["modalCategories"]),
    ...mapGetters("app", ["category"]),
  },
  data() {
    return {
      selected: "",
      categories: [],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "modalCategories.show": async function (val) {
      if (val) {
        await this.getCategories();
      }
    },
  },
  methods: {
    ...mapActions({
      _setCategory: "app/setCategory",
    }),
    close() {
      this.hideCategories();
    },
    async getCategories() {
      this.categories = [];
      const res = await RevampService.getCategories();
      if (res.status === 1) {
        this.categories.push("全て");
        this.categories.push(...res.data);
      }
    },
    onSelectChanged(event, category) {
      if (event.target.checked) {
        this.selected = category;
      }
    },
    onClickSelect() {
      this._setCategory(this.selected);
      this.close();
    },
  },
};
</script>

<style scoped>
.detail {
  height: 80%;
}

.swiper-container {
  height: 20%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
