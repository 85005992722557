import { requestGet, requestPost } from "../utils/request";
import urljoin from "url-join";
import { Constants } from "../utils/constants";

export default {
  async getInfo(images, type = "revamp") {
    const config = {
      headers: {
        "x-api-key": Constants.REVAMP_API_KEY,
      },
    };

    const formData = new FormData();
    for (const image of images) {
      formData.append("images[]", image);
    }
    formData.append("type", type);
    return requestPost(
      urljoin(Constants.REVAMP_INFO, "api/find-products"),
      formData,
      config
    );
  },
  async predict(file, category, nr_retr = 5, appCode = "revamp") {
    const config = {
      headers: {
        "x-api-key": Constants.REVAMP_API_KEY,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("app_code", appCode);
    formData.append("category", category);
    formData.append("nr_retr", nr_retr);
    return requestPost(Constants.REVAMP_PREDICT, formData, config);
  },
  async getCategories(type = "revamp") {
    const config = {
      headers: {
        "x-api-key": Constants.REVAMP_API_KEY,
      },
    };

    return requestGet(
      urljoin(Constants.REVAMP_INFO, "api/categories"),
      { type },
      config
    );
  },
  async postReportImage(image, text, type = "revamp") {
    const config = {
      headers: {
        "x-api-key": Constants.REVAMP_API_KEY,
      },
    };

    const formData = new FormData();
    formData.append("image", image);
    formData.append("text", text);
    formData.append("type", type);
    return requestPost(
      urljoin(Constants.REVAMP_INFO, "api/report-image"),
      formData,
      config
    );
  },
};
