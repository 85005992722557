import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Axios from "axios";
import i18n from "@/lang";
import VueToast from "vue-toast-notification";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";

import "vue-toast-notification/dist/theme-sugar.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@/scss/app.scss";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("VPopover", VPopover);
Vue.use(VueToast, {
  position: "top",
  duration: 2000,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
