import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import enLocale from "./en";
import jpLocale from "./jp";

Vue.use(VueI18n);

export const messages = {
  en: {
    ...enLocale,
  },
  jp: {
    ...jpLocale,
  },
};

export function getLanguage() {
  const chooseLanguage = Cookies.get("language");
  if (chooseLanguage) {
    return chooseLanguage;
  }
  // default language
  return "jp";
}

export function loadLocaleMessages() {
  const locales = require.context("./", true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      if (locale !== "index") {
        messages[locale] = locales(key);
      }
    }
  });
  return messages;
}

export const i18n = new VueI18n({
  // set locale
  // options: en, ...
  locale: getLanguage(),
  // set locale messages
  messages,
});

export default i18n;
export const languages = Object.getOwnPropertyNames(loadLocaleMessages());
export const selectedLocale =
  getLanguage() || process.env.VUE_APP_I18N_LOCALE || "en";
