import { mapActions } from "vuex";

export const ModalMixin = {
  methods: {
    ...mapActions({
      _showModalDetail: "modals/showModalDetail",
      _hideModalDetail: "modals/hideModalDetail",
      _showModalCategories: "modals/showModalCategories",
      _hideModalCategories: "modals/hideModalCategories",
      _showModalConfirm: "modals/showModalConfirm",
      _hideModalConfirm: "modals/hideModalConfirm",
      _showModalReport: "modals/showModalReport",
      _hideModalReport: "modals/hideModalReport",
      _showModalInputSaveName: "modals/showModalInputSaveName",
      _hideModalInputSaveName: "modals/hideModalInputSaveName",
    }),
    showDetail({ item, selections }) {
      this._showModalDetail({ item, selections });
    },
    hideDetail() {
      this._hideModalDetail();
    },
    showCategories() {
      this._showModalCategories();
    },
    hideCategories() {
      this._hideModalCategories();
    },
    showConfirm({ title, message, callback }) {
      this._showModalConfirm({ title, message, callback });
    },
    hideConfirm() {
      this._hideModalConfirm();
    },
    showReport({ callback }) {
      this._showModalReport({ callback });
    },
    hideReport() {
      this._hideModalReport();
    },
    showInputSaveName({ callback }) {
      this._showModalInputSaveName({ callback });
    },
    hideInputSaveName() {
      this._hideModalInputSaveName();
    },
  },
};
