export default {
  candidate_list: "候補一覧",
  selected_note: "候補から選択した商品がここに表示されます",
  scanning_note: "スキャンを開始！",
  save_list: "保存一覧",
  selection_list: "選択一覧",
  categories_list: "カテゴリー選択",
  created_date: "作成日",
  classification: "分類",
  price: "買取価格",
  classification_1: "分類1",
  classification_2: "分類2",
  classification_3: "分類3",
  classification_4: "分類4",
  conditon_name: "使用条件",
  sku_cd: "インストアJAN",
  save: "保存",
  delete: "削除",
  product_addition: "商品追加",
  toast: {
    success: "成功しました。",
    not_found: "商品の認識に失敗しました。\n再度実行してください。",
    network_error:
      "サーバーに接続できません。しばらく待って、再度実行してください。",
    info_error: "商品の認識に失敗しました。\n再度実行してください。",
  },
  mode: {
    all: "全て選択",
    unselect_all: "すべて選択解除",
    delete: "削除モード",
    release: "モード解除",
  },
  modal: {
    delete_it_question: "この商品を削除しますか？",
    delete_them_question: "この商品を削除しますか？",
    ok: "同意する",
    cancel: "キャンセル",
    select: "選択",
    send: "送信",
    detail_title: "商品詳細",
    report_title: "マッチ無し通報",
    report_placeholder: "詳細内容",
    save_title: "選択商品の保存",
    save_note: "選択商品がこのリストに保存されます。",
    save_placeholder: "件名",
    category_title: "カテゴリー",
  },
};
