<template>
  <div
    class="camera-wrapper"
    ref="camera"
    :style="{ height: `${cameraHeight}px` }"
  >
    <div class="camera-overlay" @click.prevent="takePicture()">
      <div class="overlay-wrapper">
        <img
          class="top-left"
          src="../../assets/camera/camera-corner-top-left.png"
        />
        <img
          class="top-right"
          src="../../assets/camera/camera-corner-top-right.png"
        />
        <img
          class="bottom-left"
          src="../../assets/camera/camera-corner-bottom-left.png"
        />
        <img
          class="bottom-right"
          src="../../assets/camera/camera-corner-bottom-right.png"
        />
        <div class="hint" v-show="!loading">画面をタップしてください</div>
        <div class="lds-ring" v-show="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>{{ debugData }}</div>
      </div>
    </div>
    <video
      ref="video"
      class="camera-video"
      autoplay
      playsinline
      v-show="!loading"
      :style="{
        top: `${cameraPosition.top}px`,
        left: `${cameraPosition.left}px`,
      }"
    ></video>
    <canvas ref="canvas" class="camera-canvas" v-show="picture"></canvas>
  </div>
</template>

<script>
// import Vue from "vue";
import RevampService from "@/services/revamp";
import { mapActions, mapGetters } from "vuex";
import {
  EventBus,
  EVENT_SAVE_CLICK_LOAD_MORE,
  EVENT_SAVE_CLICK_SELECTED_CANDIDATE,
} from "@/utils/event-bus";

export default {
  data() {
    return {
      picture: null,
      constraints: {
        video: {
          facingMode: "environment",
        },
        audio: false,
      },
      cameraWidth: 0,
      cameraHeight: 0,
      cameraPosition: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      output: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      stream: null,
      predict: [],
      debugData: "",
      blob: null,
      nrRetr: 5,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("app", ["category"]),
  },
  created() {
    this.setUpEventBus();
  },
  destroyed() {
    this.clearEventBus();
  },
  async mounted() {
    this.updateCameraWrapperPosition();
    await this.startCamera();
    setTimeout(this.updateCameraPosition, 500);
  },
  methods: {
    ...mapActions({
      _setPredictResult: "app/setPredictResult",
      _setNotMatchImage: "app/setNotMatchImage",
    }),
    setUpEventBus() {
      EventBus.$on(EVENT_SAVE_CLICK_LOAD_MORE, this.onLoadMoreRetr);
      EventBus.$on(EVENT_SAVE_CLICK_SELECTED_CANDIDATE, this.onSelected);
    },
    clearEventBus() {
      EventBus.$off(EVENT_SAVE_CLICK_LOAD_MORE, this.onLoadMoreRetr);
      EventBus.$off(EVENT_SAVE_CLICK_SELECTED_CANDIDATE, this.onSelected);
    },

    async startCamera() {
      await navigator.mediaDevices.getUserMedia(this.constraints);
      const stream = await navigator.mediaDevices.getUserMedia(
        this.constraints
      );
      this.stream = stream;
      this.$refs.video.srcObject = stream;
    },
    async stopCamera() {
      let tracks = this.$refs.video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      this.$refs.video.srcObject = null;
    },
    updateCameraWrapperPosition() {
      // update wrapper height
      console.log("screen width: ", this.$refs.camera.clientWidth);
      this.cameraWidth = this.$refs.camera.clientWidth;
      this.cameraHeight = this.cameraWidth;
    },
    updateCameraPosition() {
      // update video position
      const videoWidth = this.$refs.video.videoWidth;
      const videoHeight = this.$refs.video.videoHeight;

      if (videoWidth == 0) {
        setTimeout(this.updateCameraPosition, 500);
        return;
      }

      // console.log("video: ", videoWidth, videoHeight);
      // this.debugData += videoWidth + ' - ' + videoHeight + '\n';
      // this.debugData += this.$refs.video.clientWidth + ' - ' + this.$refs.video.clientHeight;

      const ratio = videoWidth / videoHeight;
      if (ratio >= 1) {
        // TODO: fix for desktop
        this.cameraPosition.height = this.cameraHeight;
        this.cameraPosition.width =
          (this.cameraHeight / videoHeight) * videoWidth;
        this.cameraPosition.top = 0;
        this.cameraPosition.left =
          -(this.cameraPosition.width - this.cameraWidth) / 2;

        this.output.width = videoHeight;
        this.output.height = videoHeight;
        this.output.top = 0;
        this.output.left = (videoWidth - this.output.width) / 2;
      } else {
        this.cameraPosition.width = videoWidth;
        this.cameraPosition.height =
          (this.cameraWidth / videoWidth) * videoHeight;
        this.cameraPosition.top =
          -(this.cameraPosition.height - this.cameraHeight) / 2;
        this.cameraPosition.left = 0;

        this.output.width = videoWidth;
        this.output.height = videoWidth;
        this.output.top = (videoHeight - this.output.height) / 2;
        this.output.left = 0;
      }
    },
    async takePicture() {
      this.loading = true;

      // Take picture
      let canvas = this.$refs.canvas;
      canvas.width = this.output.width;
      canvas.height = this.output.height;
      canvas
        .getContext("2d")
        .drawImage(
          this.$refs.video,
          this.output.left,
          this.output.top,
          this.output.width,
          this.output.height,
          0,
          0,
          this.output.width,
          this.output.height
        );
      this.picture = this.$refs.canvas.toDataURL("image/jpeg", 1);
      this.blob = this.dataURItoBlob(this.picture);

      await this.onPredict(this.blob, this.nrRetr);
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    async onPredict(image, nr_retr) {
      const res = await RevampService.predict(image, this.category, nr_retr);
      if (res.status === 0) {
        const matchedFiles = res.matched_files;
        Object.keys(matchedFiles).forEach((key) => {
          const matchedFile = matchedFiles[key];
          this.predict.push(matchedFile["image"]);
        });

        // Get product info
        await this.getProductInfo();
      } else if (res.status === -1) {
        this.$toast.error(this.$t("toast.network_error"));
        this.reset();
      } else {
        // Toast not found
        this.$toast.error(this.$t("toast.not_found"));

        // Reset
        await this.getProductInfo();
      }
      // Set image not found
      this._setNotMatchImage(image);
    },
    async getProductInfo() {
      if (this.predict.length > 0) {
        const res = await RevampService.getInfo(this.predict);
        if (res.status === 1) {
          this._setPredictResult(Object.values(res.data));
        } else {
          this.$toast.error(this.$t("toast.info_error"));
        }
      } else {
        this._setPredictResult([]);
      }
      this.reset();
    },
    onSelected() {
      this.nrRetr = 5;
      this.blob = null;
    },
    async onLoadMoreRetr() {
      this.loading = true;
      if (this.nrRetr < 20) {
        this.nrRetr += 5;
      }
      await this.onPredict(this.blob, this.nrRetr);
    },
    reset() {
      this.predict = [];
      this.picture = null;
      this.loading = false;
    },
  },
};
</script>
