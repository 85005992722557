import Vue from "vue";

export async function requestGet(url, params = {}, config = {}) {
  try {
    const res = await Vue.prototype.$http.get(url, { ...config, params });
    return res.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) {
        return error.response.data;
      } else {
        return {
          status: -1,
          msg: error.response.statusText,
        };
      }
    } else {
      return {
        status: -1,
        msg: error,
      };
    }
  }
}

export async function requestPost(url, data = {}, config = {}) {
  try {
    const res = await Vue.prototype.$http.post(url, data, config);
    return res.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) {
        return error.response.data;
      } else {
        return {
          status: -1,
          msg: error.response.statusText,
        };
      }
    } else {
      return {
        status: -1,
        msg: error,
      };
    }
  }
}
