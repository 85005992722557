export const getters = {
  predictResult: (state) => {
    return state.predictResult;
  },
  selectionList: (state) => {
    return state.selectionList;
  },
  saveList: (state) => {
    return state.saveList;
  },
  deleteMode: (state) => {
    return state.deleteMode;
  },
  category: (state) => {
    return state.category;
  },
  notMatchImage: (state) => {
    return state.notMatchImage;
  },
  saveListItems: (state) => {
    return state.saveListItems;
  },
};
