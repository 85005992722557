<template>
  <div v-if="Object.keys(saveList).length > 0" class="d-flex flex-column pt-2">
    <div v-if="deleteMode" class="form-check pb-2">
      <!-- Select all -->
      <input
        class="form-check-input"
        type="checkbox"
        id="flexCheckChecked"
        @change="onSelectAll($event)"
      />
      <label class="form-check-label" for="flexCheckChecked">
        {{ $t("mode.all") }}
      </label>
    </div>

    <!-- Content -->
    <div
      v-for="(value, name) in saveList"
      :key="name"
      class="d-flex flex-column pb-2"
    >
      <div class="d-flex align-items-center flex-row w-100 pb-2">
        <!-- Select item -->
        <input
          v-if="deleteMode"
          v-model="deleteList"
          type="checkbox"
          :value="name"
          class="form-check-input me-2"
          @change="onChecked($event, name)"
        />

        <!-- Title, date time, button action -->
        <div class="d-flex flex-column w-50 justify-content-start">
          <span class="fw-bold fs-3">
            {{ name }} {{ "(" + value.items.length + ")" }}
          </span>
          <span class="text-12 text-gray">{{ value.date }}</span>
        </div>
        <div class="d-flex w-50 h-50 justify-content-end">
          <!-- Button delete -->
          <button
            type="button"
            class="btn btn-danger btn-sm me-1"
            style="color: #fff"
            @click="onDeleteItem(name)"
          >
            <i class="fa fa-trash-alt" />
            <span class="ps-1">{{ $t("delete") }}</span>
          </button>

          <!-- Button new save -->
          <router-link
            v-slot="{ href, navigate }"
            :to="{ name: ROUTE_NAMES.NEW_SAVE, params: { title: name } }"
            custom
          >
            <button
              :href="href"
              type="button"
              class="btn btn-info btn-sm"
              style="color: #fff"
              @click="navigate"
            >
              <i class="fa fa-plus" />
              <span class="ps-1">{{ $t("product_addition") }}</span>
            </button>
          </router-link>
        </div>
      </div>

      <!-- item info -->
      <div class="d-flex w-100 overflow-auto">
        <div
          v-for="(item, index) in value.items"
          :key="index"
          class="d-flex flex-column border p-2 me-2"
          style="width: 130px"
        >
          <img :src="item.images[0]" style="height: 80px" />
          <label class="text-break text-center">{{ item.sku_name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AppMixin } from "@/mixins/AppMixin";
import { ModalMixin } from "@/mixins/ModalMixin";
import { EventBus, EVENT_CLICK_DELETE_SAVED } from "@/utils/event-bus";

export default {
  mixins: [AppMixin, ModalMixin],
  data() {
    return {
      deleteList: [],
    };
  },
  computed: {
    ...mapGetters("app", ["deleteMode", "saveList"]),
  },
  created() {
    this.setUpEventBus();
  },
  destroyed() {
    this.clearEventBus();
  },
  methods: {
    ...mapActions({
      _removeToSaveList: "app/removeToSaveList",
    }),
    setUpEventBus() {
      EventBus.$on(EVENT_CLICK_DELETE_SAVED, this.onDeleteAllSelected);
    },
    clearEventBus() {
      EventBus.$off(EVENT_CLICK_DELETE_SAVED, this.onDeleteAllSelected);
    },
    onDeleteItem(title) {
      this.showConfirm({
        title: this.$t("delete"),
        message: this.$t("modal.delete_it_question"),
        callback: (ok) => {
          if (ok) {
            this._removeToSaveList(title);
          }
        },
      });
    },
    onSelectAll(event) {
      if (event.target.checked) {
        this.deleteList = Object.keys(this.saveList);
      } else {
        this.deleteList = [];
      }
    },
    onChecked(event, name) {
      const idx = this.deleteList.indexOf(name);
      if (event.target.checked && idx === -1) {
        this.deleteList.push(name);
      } else if (!event.target.checked && idx !== -1) {
        this.deleteList.splice(idx, 1);
      }
    },
    onDeleteAllSelected() {
      this.showConfirm({
        title: this.$t("delete"),
        message: this.$t("modal.delete_them_question"),
        callback: (ok) => {
          if (ok) {
            this.deleteList.forEach((item) => {
              this._removeToSaveList(item);
            });
          }
        },
      });
    },
  },
};
</script>
