import Vue from "vue";
import dayjs from "dayjs";
import { CookieKey } from "@/utils/constants";

export const mutations = {
  SET_PREDICT_RESULT: (state, result) => {
    state.predictResult = result;
  },
  SET_SELECTION_LIST: (state, seletionList) => {
    state.selectionList = seletionList;
  },
  PUSH_TO_SELECTION_LIST: (state, item) => {
    const selected = state.selectionList.find((i) => {
      return i.sku_cd === item.sku_cd;
    });
    if (!selected) {
      const now = dayjs().tz("Asia/Tokyo");
      item["date"] = now.format("YYYY/MM/DD");
      item["time"] = now.format("HH:mm:ss");
      state.selectionList.push(item);
      localStorage.setItem(
        CookieKey.SELECTION,
        JSON.stringify(state.selectionList)
      );
    }
  },
  REMOVE_TO_SELECTION_LIST: (state, skuCd) => {
    const idx = state.selectionList.findIndex((i) => {
      return i.sku_cd === skuCd;
    });

    if (idx !== -1) {
      state.selectionList.splice(idx, 1);
      localStorage.setItem(
        CookieKey.SELECTION,
        JSON.stringify(state.selectionList)
      );
    }
  },
  REMOVE_ALL_SELECTION: (state) => {
    state.selectionList = [];
    localStorage.removeItem(CookieKey.SELECTION);
  },
  SET_ALL_SAVE_LIST: (state, list) => {
    state.saveList = list;
  },
  SET_SAVE_LIST: (state, { title, items }) => {
    state.saveList[title] = {
      items: items,
      date: dayjs().tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm:ss"),
    };
    localStorage.setItem(CookieKey.SAVE, JSON.stringify(state.saveList));
  },
  PUSH_TO_SAVE_LIST: (state, { title, item }) => {
    if (title in state.saveList) {
      const saveListItems = state.saveList[title].items;
      if (saveListItems) {
        const selected = saveListItems.find((i) => {
          return i.sku_cd === item.sku_cd;
        });
        if (!selected) {
          const now = dayjs().tz("Asia/Tokyo");
          item["date"] = now.format("YYYY/MM/DD");
          item["time"] = now.format("HH:mm:ss");
          saveListItems.push(item);
          state.saveList[title].items = saveListItems;
          state.saveListItems = saveListItems;
          localStorage.setItem(CookieKey.SAVE, JSON.stringify(state.saveList));
        }
      }
    }
  },
  REMOVE_TO_SAVE_LIST: (state, title) => {
    if (title in state.saveList) {
      Vue.delete(state.saveList, title);
      localStorage.setItem(CookieKey.SAVE, JSON.stringify(state.saveList));
    }
  },
  REMOVE_ITEM_FROM_SAVE_LIST: (state, { title, skuCd }) => {
    if (title in state.saveList) {
      const saveListItems = state.saveList[title].items;
      if (saveListItems) {
        const idx = saveListItems.findIndex((i) => {
          return i.sku_cd === skuCd;
        });
        if (idx !== -1) {
          Vue.delete(saveListItems, idx);
          state.saveList[title].items = saveListItems;
        }
      }
      localStorage.setItem(CookieKey.SAVE, JSON.stringify(state.saveList));
    }
  },
  SET_SAVE_LIST_ITEMS: (state, title) => {
    if (title in state.saveList) {
      state.saveListItems = state.saveList[title].items;
    }
  },
  CHANGE_DETAIL_MODE: (state) => {
    state.deleteMode = !state.deleteMode;
  },
  SET_CATEGORY: (state, category) => {
    state.category = category;
    localStorage.setItem(CookieKey.CATEGORY, state.category);
  },
  SET_NOT_MATCH_IMAGE: (state, image) => {
    state.notMatchImage = image;
  },
};
