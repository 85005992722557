import Vue from "vue";
import Vuex from "vuex";
import AppModule from "./modules/app/app.module";
import ModalModule from "./modules/modals/modals.module";

Vue.use(Vuex);

const modules = {
  app: AppModule,
  modals: ModalModule,
};

const store = new Vuex.Store({
  modules,
});

export default store;
