export const actions = {
  setPredictResult({ commit }, result) {
    commit("SET_PREDICT_RESULT", result);
  },
  setSelectionList({ commit }, selectionList) {
    commit("SET_SELECTION_LIST", selectionList);
  },
  pushToSelectionList({ commit }, item) {
    commit("PUSH_TO_SELECTION_LIST", item);
  },
  removeToSelectionList({ commit }, skuCd) {
    commit("REMOVE_TO_SELECTION_LIST", skuCd);
  },
  removeAllSelection({ commit }) {
    commit("REMOVE_ALL_SELECTION");
  },
  setAllSaveList({ commit }, list) {
    commit("SET_ALL_SAVE_LIST", list);
  },
  setSaveList({ commit }, { title, items }) {
    commit("SET_SAVE_LIST", { title, items });
  },
  setSaveListItems({ commit }, title) {
    commit("SET_SAVE_LIST_ITEMS", title);
  },
  pushToSaveList({ commit }, { title, item }) {
    commit("PUSH_TO_SAVE_LIST", { title, item });
  },
  removeToSaveList({ commit }, title) {
    commit("REMOVE_TO_SAVE_LIST", title);
  },
  removeItemFromSaveList({ commit }, { title, skuCd }) {
    commit("REMOVE_ITEM_FROM_SAVE_LIST", { title, skuCd });
  },
  changeDetailMode({ commit }) {
    commit("CHANGE_DETAIL_MODE");
  },
  setCategory({ commit }, category) {
    commit("SET_CATEGORY", category);
  },
  setNotMatchImage({ commit }, image) {
    commit("SET_NOT_MATCH_IMAGE", image);
  },
};
