<template>
  <div class="scanning-group">
    <div
      v-if="result.length > 0"
      class="scanning-group-scrollable h-100 w-100 py-2 m-0"
    >
      <img
        v-for="(item, index) in result"
        :key="index"
        :src="item.images[0]"
        alt=""
        class="image-item img-thumbnail h-100 me-2"
        @click="selectItem(item)"
      />
      <button type="btn" class="btn btn-more" @click="onClickLoadMore()">
        <i class="fa fa-ellipsis-h fa-3x" />
      </button>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-center h-100 w-100 flex-column"
    >
      <img
        src="/resources/Icon/48px/NoResult.png"
        srcset="/resources/Icon/48px/NoResult@2x.png 2x"
        class="my-0 pe-2"
      />
      <span class="text-gray">{{ $t("scanning_note") }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  EventBus,
  EVENT_CLICK_LOAD_MORE,
  EVENT_CLICK_SELECTED_CANDIDATE,
} from "@/utils/event-bus";

export default {
  data() {
    return {
      result: [],
    };
  },
  watch: {
    predictResult: function (val) {
      this.result = val;
    },
  },
  computed: {
    ...mapGetters("app", ["predictResult"]),
  },
  methods: {
    ...mapActions({
      _pushToSelectionList: "app/pushToSelectionList",
      _setPredictResult: "app/setPredictResult",
      _setNotMatchImage: "app/setNotMatchImage",
    }),
    selectItem(item) {
      this._pushToSelectionList(item);
      this._setPredictResult([]);
      this._setNotMatchImage("");
      this.onClickSelected();
    },
    async onClickLoadMore() {
      EventBus.$emit(EVENT_CLICK_LOAD_MORE, true);
    },
    async onClickSelected() {
      EventBus.$emit(EVENT_CLICK_SELECTED_CANDIDATE, true);
    },
  },
};
</script>

<style scoped>
img {
  display: block;
  max-width: 100%;
  height: auto;
}

.scanning-group {
  height: 100%;
  width: 100%;
}

.scanning-group > .scanning-group-scrollable {
  flex: 1 1 0;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scanning-group > .scanning-group-scrollable > .image-item {
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.btn-more {
  height: 100%;
  width: 80px;
  color: cornflowerblue;
}
</style>
