<template>
  <div class="d-flex justify-content-center flex-wrap">
    <!-- Result area -->
    <div class="d-flex home-scanning">
      <Result />
    </div>

    <div class="d-flex home-camera">
      <Camera />
    </div>

    <!-- Selected area -->
    <div class="d-flex home-selected">
      <Selected />
    </div>
  </div>
</template>

<script>
import Result from "@/components/new-save/result.vue";
import Camera from "@/components/new-save/camera.vue";
import Selected from "@/components/new-save/selected.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Result,
    Camera,
    Selected,
  },
  mounted() {
    this._setNotMatchImage("");
  },
  methods: {
    ...mapActions({
      _setNotMatchImage: "app/setNotMatchImage",
    }),
  },
};
</script>
