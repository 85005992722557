<template>
  <nav
    v-if="isShowFooter"
    class="navbar default-layout-navbar p-0 fixed-bottom d-flex flex-row footer"
  >
    <div class="d-flex flex-row justify-content-center w-100">
      <div class="w-30 d-flex justify-content-center p-0">
        <router-link
          :to="{ name: ROUTE_NAMES.SAVED_LIST }"
          class="nav-link d-flex align-items-center"
        >
          <img
            src="/resources/Icon/24px/SavedList/SavedListGrey.png"
            srcset="/resources/Icon/24px/SavedList/SavedListGrey@2x.png 2x"
            class="my-0 pe-1"
          />
          <span class="text-gray text-13">{{ $t("save_list") }}</span>
        </router-link>
      </div>

      <div class="w-30 d-flex justify-content-center p-0">
        <router-link
          :to="{ name: ROUTE_NAMES.SELECTED_LIST }"
          class="nav-link d-flex align-items-center"
        >
          <img
            src="/resources/Icon/24px/ProductList.png"
            srcset="/resources/Icon/24px/ProductList@2x.png 2x"
            class="my-0 pe-1"
          />
          <span class="text-gray text-13">{{ $t("selection_list") }}</span>
        </router-link>
      </div>

      <div class="w-40 d-flex justify-content-center p-0">
        <a
          href="#"
          class="d-flex align-items-center text-decoration-none"
          @click="showModalCategories"
        >
          <img
            src="/resources/Icon/tabbar-filter-icon.png"
            srcset="/resources/Icon/tabbar-filter-icon@2x.png 2x"
            class="my-0 pe-1"
          />
          <span class="text-gray text-13">{{ $t("categories_list") }}</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { AppMixin } from "@/mixins/AppMixin";
import { ModalMixin } from "@/mixins/ModalMixin";

export default {
  mixins: [AppMixin, ModalMixin],
  computed: {
    isShowFooter() {
      return this.$route.meta?.footer;
    },
  },
  methods: {
    showModalCategories() {
      this.showCategories();
    },
  },
};
</script>
