export const mutations = {
  SHOW_MODAL_DETAIL: (state, { item, selections }) => {
    state.modalDetail = {
      show: true,
      item: item,
      selections: selections,
    };
  },
  HIDE_MODAL_DETAIL: (state) => {
    state.modalDetail = {
      show: false,
      item: null,
    };
  },
  SHOW_MODAL_CATEGORIES: (state) => {
    state.modalCategories = {
      show: true,
    };
  },
  HIDE_MODAL_CATEGORIES: (state) => {
    state.modalCategories = {
      show: false,
    };
  },
  SHOW_MODAL_CONFIRM: (state, { title, message, callback }) => {
    state.modalConfirm = {
      show: true,
      title: title,
      message: message,
      callback: callback,
    };
  },
  HIDE_MODAL_CONFIRM: (state) => {
    state.modalConfirm = {
      show: false,
      title: "",
      message: "",
      callback: null,
    };
  },
  SHOW_MODAL_REPORT: (state, { callback }) => {
    state.modalReport = {
      show: true,
      callback: callback,
    };
  },
  HIDE_MODAL_REPORT: (state) => {
    state.modalReport = {
      show: false,
      callback: null,
    };
  },
  SHOW_MODAL_INPUT_SAVE_NAME: (state, { callback }) => {
    state.modalInputSaveName = {
      show: true,
      callback: callback,
    };
  },
  HIDE_MODAL_INPUT_SAVE_NAME: (state) => {
    state.modalInputSaveName = {
      show: false,
      callback: null,
    };
  },
};
