import Vue from "vue";
export const EventBus = new Vue();
window.vueEvent = EventBus;

export const EVENT_CLICK_DELETE_SELECTION = "delete-selection";
export const EVENT_CLICK_DELETE_SAVED = "delete-save";
export const EVENT_CLICK_LOAD_MORE = "load-more-candidate";
export const EVENT_CLICK_SELECTED_CANDIDATE = "selectd-candidate";
export const EVENT_SAVE_CLICK_LOAD_MORE = "save-load-more-candidate";
export const EVENT_SAVE_CLICK_SELECTED_CANDIDATE = "save-selectd-candidate";
