export const actions = {
  showModalDetail({ commit }, { item, selections }) {
    commit("SHOW_MODAL_DETAIL", { item, selections });
  },
  hideModalDetail({ commit }) {
    commit("HIDE_MODAL_DETAIL");
  },
  showModalCategories({ commit }) {
    commit("SHOW_MODAL_CATEGORIES");
  },
  hideModalCategories({ commit }) {
    commit("HIDE_MODAL_CATEGORIES");
  },
  showModalConfirm({ commit }, { title, message, callback }) {
    commit("SHOW_MODAL_CONFIRM", { title, message, callback });
  },
  hideModalConfirm({ commit }) {
    commit("HIDE_MODAL_CONFIRM");
  },
  showModalReport({ commit }, { callback }) {
    commit("SHOW_MODAL_REPORT", { callback });
  },
  hideModalReport({ commit }) {
    commit("HIDE_MODAL_REPORT");
  },
  showModalInputSaveName({ commit }, { callback }) {
    commit("SHOW_MODAL_INPUT_SAVE_NAME", { callback });
  },
  hideModalInputSaveName({ commit }) {
    commit("HIDE_MODAL_INPUT_SAVE_NAME");
  },
};
