import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home";
import Selection from "@/views/selection";
import Save from "@/views/save";
import NewSave from "@/views/new-save";

import { ROUTE_NAME } from "@/utils/constants";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: ROUTE_NAME.HOME,
    component: Home,
    meta: {
      header: 1,
      footer: true,
    },
  },
  {
    path: "/selection",
    name: ROUTE_NAME.SELECTED_LIST,
    component: Selection,
    meta: {
      header: 2,
      footer: false,
    },
  },
  {
    path: "/save",
    name: ROUTE_NAME.SAVED_LIST,
    component: Save,
    meta: {
      header: 2,
      footer: false,
    },
  },
  {
    path: "/new-save/:title",
    name: ROUTE_NAME.NEW_SAVE,
    component: NewSave,
    meta: {
      header: 1,
      footer: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
