import { actions } from "./modals.actions";
import { getters } from "./modals.getters";
import { mutations } from "./modals.mutations";
import { state } from "./modals.state";

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
